<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="obAccountType.active"></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="8">
      <form-field-text v-model="obAccountType.name" required />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obAccountType.code" label="code" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { AccountType } from "@planetadeleste/vue-mc-gw";

@Component
export default class AccounttypesSettings extends Vue {
  @VModel({ type: Object, default: () => new AccountType() })
  obAccountType!: AccountType;
}
</script>
